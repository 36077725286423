module.exports = {
  messages: {
    ES: {
      title: "P r o x i m a m e n t e!",
      buttonTitle: "Página de inicio",
    },
    US: {
      title: "C o m i n g     s o o n!",
      buttonTitle: "Home page",
    }
  }
}
